import React, { Children, useState, useEffect, useRef, useContext } from 'react';
import styled from '@emotion/styled';
import { darken } from 'polished';
import { withRouter } from 'next/router';
import NextLink from 'next/link';
import { useSpring, animated } from 'react-spring';
import { math } from 'polished';

import { DispatchContext } from 'Context/DispatchContext';
import { StateContext } from 'Context/StateContext';

import { isIE11 } from 'Utils/browser';
import * as v from 'Utils/variables';
import colors from 'Utils/theme';

import useMedia from 'Hooks/useMedia';
import useFocusMethod from 'Hooks/useFocusMethod';
import useWindowSize from 'Hooks/useWindowSize';
import useScroll from 'Hooks/useScroll';

import Badge from 'Components/badge/Badge';
import ChatLink from 'Components/chat-link';

import Button from '../button';
import Container from '../container';
import Row from '../row';
import Column from '../column';
import Link from '../link';
import Hamburger from './Hamburger';
import SubMenu from '../sub-menu';

import PlusUpLogo from 'Icons/PlusUpLogo';

import { Copy } from 'Styles/typography';
import { responsiveFont } from 'Styles/helpers';

//TODO: Fix the breakpoint situation. there are too many and they are confusing
const minMobileBreakpoint = '670px';
const minTabletBreakpoint = '1280px';
const maxTabletBreakpoint = '1279px';

const Fixed = styled.nav`
  position: sticky;
  top: ${(props) => (props.topBarHidden ? 0 : '45px')};
  right: 0;
  left: 0;
  z-index: 101;

  padding-top: 19px;
  padding-bottom: 19px;

  background-color: ${(props) => (props.inverted ? colors.inHome.blue : colors.white)};
  box-shadow: ${(props) => (props.shadow ? '0 1px 2px rgba(0, 0, 0, 0.15)' : 'none')};

  transition: box-shadow 200ms ease-in-out, background-color 200ms ease-in-out;

  @media (min-width: ${minTabletBreakpoint}) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${v.min1280}) {
    min-height: 54px;
  }
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  width: ${(props) => (props.isMobile ? '100%' : 'auto')};
`;

const HeaderLink = styled(Link)`
  display: flex;
  flex-shrink: 0;
  align-items: center;

  svg {
    width: 178px;
    height: 38px;
  }
`;

const Topbar = styled(Container)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  color: ${colors.white};
  background: ${colors.walmart.blue};
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;

  @media (min-width: ${v.minTablet}) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: ${v.minDesktop}) {
    max-width: 100%;
  }
`;

const TopbarContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @media (min-width: ${v.minMobile}) {
    justify-content: flex-start;
  }
`;

const TopbarRow = styled(Row)`
  height: 100%;
  margin: auto;
  max-width: ${math(`${v.pageWidth} + ${v.containerGutterMobile}`)};

  @media (min-width: ${v.minMobile}) {
    max-width: ${math(`${v.pageWidth} + ${v.containerGutterMobile} * 2`)};
  }

  @media (min-width: ${v.minTablet}) {
    padding-left: ${v.containerGutterTablet};
    padding-right: ${v.containerGutterTablet};

    max-width: ${math(`${v.pageWidth} + ${v.containerGutterTablet} * 2`)};
  }

  @media (min-width: ${v.minDesktop}) {
    padding-left: ${v.containerGutterDesktop};
    padding-right: ${v.containerGutterDesktop};

    max-width: ${math(`${v.pageWidth} + ${v.containerGutterDesktop} * 2`)};
  }
`;

const TopBarColumn = styled(Column)`
  padding: 0;
`;

const HeaderCopy = styled(Copy)`
  ${responsiveFont({ sizes: '14, 16' })};

  color: ${colors.white};
  line-height: 1.5;

  & button,
  & a {
    ${responsiveFont({ sizes: '14, 16' })};
    position: relative;

    cursor: pointer;
    color: #fff;
    text-decoration: underline;

    transition: color 100ms ease-in-out;

    &:hover {
      color: ${darken(0.25, '#fff')};
    }
  }
`;

const HeaderButton = styled(Button)`
  & + & {
    margin-left: 20px;
    margin-bottom: 0;
  }
`;

const ListWrapper = styled(animated.div)`
  display: none;
  align-items: center;

  position: relative;
  flex-direction: column;

  margin-left: auto;

  @media (max-width: ${maxTabletBreakpoint}) {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    min-height: 100vh;

    padding-top: 125px;
    padding-bottom: 50px;

    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    background-color: #fff;

    will-change: opacity, transform;
    overflow-x: scroll;
  }

  @media (min-width: ${minTabletBreakpoint}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

const List = styled.ul`
  flex-shrink: 0;
  @media (max-width: ${maxTabletBreakpoint}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: ${minTabletBreakpoint}) {
    display: flex;
    align-items: center;

    padding-top: 0;
  }
`;

const SignupWrapper = styled(Container)`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;

  @media (min-width: ${minTabletBreakpoint}) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    flex-shrink: 1;

    padding-left: 0;
    padding-right: 0;

    max-width: inherit;
    width: inherit;
  }

  ${HeaderButton} {
    max-width: none;
    margin-top: 20px;

    @media (min-width: ${minTabletBreakpoint}) {
      margin: 0;
    }
  }
`;

const ChatWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;

  margin: 20px;

  color: #4b5353;

  div {
    padding-left: 0.375em;
    color: #0a1f41;
    font-weight: 500;
  }
`;

const Item = styled.li`
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: ${`calc(${40 / 22} * 1em)`};

  & > a,
  & > button {
    display: inline-block;

    white-space: nowrap;
    font-size: 22px;
    font-weight: ${({ isSelected }) => (isSelected ? 500 : 400)};
    line-height: 26px;
    text-align: center;

    color: ${(props) => (props.inverted ? colors.white : colors.walmart.blue)};

    transition: color 200ms ease-in-out;

    &:hover {
      text-shadow: -0.004em -0.004em 0 ${colors.walmart.blue}, 0.004em -0.004em 0 ${colors.walmart.blue}, -0.004em 0.004em 0 ${colors.walmart.blue}, 0.004em 0.004em 0 ${colors.walmart.blue};
    }

    &::after {
      content: attr(title);

      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      font-weight: 500;
    }

    @media (min-width: ${minTabletBreakpoint}) {
      font-size: 16px;
    }
  }

  &::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: -6px;
    left: 0;

    width: ${({ isSelected }) => (isSelected ? '100%' : 0)};
    height: 2px;

    background-color: ${v.colors.primary};

    transition: width 100ms ease-in-out;
  }

  &:hover {
    color: #0a1f41;
  }

  @media (min-width: ${minTabletBreakpoint}) {
    ${responsiveFont({ sizes: '16, 18' })};

    margin-left: 35px;
    margin-bottom: 0;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: ${(props) => (props.hasInHome ? 0 : '35px')};
    }
  }

  @media (min-width: ${v.min1440}) {
    margin-left: 35px;

    &:last-of-type {
      margin-right: ${(props) => (props.hasInHome ? 0 : '35px')};
    }
  }
`;

const Buttons = styled.div`
  @media (max-width: ${maxTabletBreakpoint}) {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
`;

const SubMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0 0;
  min-height: 280px;

  @media (min-width: ${minMobileBreakpoint}) {
    flex-direction: row;
    margin: 0;
    min-height: 200px;
  }
  @media (min-width: ${minTabletBreakpoint}) {
    margin-left: 160px;
    margin: 40px 0;
  }

  @media (min-width: 1500px) {
    margin-left: 185px;
  }
`;

const SubMenuItem = styled.li`
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${minMobileBreakpoint}) {
    margin: 20px 20px 0 0;
  }

  @media (min-width: ${minTabletBreakpoint}) {
    margin: 0 20px 0 0;
  }
`;

const TabletButtons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: auto;
    max-width: inherit;
  }
`;

// const SkipToMainContent = styled.a`
//   left: -999px;
//   position: absolute;
//   top: auto;
//   width: 1px;
//   height: 1px;
//   overflow: hidden;
//   z-index: -999;

//   &:focus {
//     color: #fff;
//     background-color: ${colors.walmart.blue};
//     left: 50%;
//     top: 20px;
//     width: 30%;
//     height: auto;
//     margin-left: -15%;
//     overflow: auto;
//     padding: 5px;
//     border-radius: 15px;
//     text-align: center;
//     font-size: 1.2em;
//     z-index: 999;
//   }
// `;

const LogoBar = styled.div`
  position: absolute;
  pointer-events: none;

  height: 65px;

  @media (max-width: ${maxTabletBreakpoint}) {
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    background: ${(props) => (props.inverted ? colors.inHome.blue : colors.white)};
    transition: background 200ms ease-in-out;
  }

  @media (min-width: ${minTabletBreakpoint}) {
    height: 70px;
  }
`;
const CardImageWrap = styled.div``;

const CardImage = styled.img`
  display: block;

  max-width: 112px;

  border: 2px solid transparent;
  border-radius: 16px;

  transition: border 200ms ease-in-out;

  @media (min-width: ${minMobileBreakpoint}) {
    max-width: 195px;
  }

  @media (min-width: ${minTabletBreakpoint}) {
    max-width: 235px;

    border-radius: 24px;
  }
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;

  margin: 0 20px;
  flex-wrap: wrap;

  @media (min-width: ${minMobileBreakpoint}) {
    align-items: center;
    margin: 20px 0 0;
  }
`;

const CardTitleWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    margin-top: 3px;
  }
`;

const MenuCard = styled.a`
  display: flex;
  flex-direction: row;

  font-weight: 500;
  font-size: 18px;

  letter-spacing: -0.01em;
  color: #0a1f41;

  cursor: ${(props) => (props.inactive ? 'default' : 'pointer')};

  &:hover ${CardImage} {
    border: 2px solid ${(props) => (props.inactive ? 'transparent' : '#0A1F41')};
  }

  @media (min-width: ${minMobileBreakpoint}) {
    flex-direction: column;
  }
`;

const CardHeading = styled.div`
  margin-right: 8px;
  margin-bottom: 5px;
  flex-shrink: 0;

  @media (min-width: ${minMobileBreakpoint}) {
    margin-bottom: 0px;
  }
`;

const Header = withRouter(({ infoBar = null, useMinifiedMenu = false, /*leftMenu,*/ rightMenu, buttons, router, hasInHome, style = null }) => {
  const headerRef = useRef(null);
  // const [menuRef, menuSize] = typeof window === 'undefined' ? [useRef(), { height: 0 }] : useDimensions();
  const menuRef = useRef();

  const { dispatch } = useContext(DispatchContext);
  const { menuState } = useContext(StateContext);

  const { inverted } = menuState;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [isBlue, setBlue] = useState(true);
  const isMobile = useMedia(`(max-width: ${maxTabletBreakpoint})`);
  const navRef = useRef(null);
  const mainRef = useRef(null);
  // const skipToMain = useRef(null);

  const { windowWidth } = useWindowSize();
  const { scrollY } = useScroll();

  useFocusMethod();

  const [listProps, setList] = useSpring(() => ({
    opacity: 0,
    y: -100,
    pointerEvents: 'none',
    config: { mass: 0.6, friction: 16 },
  }));

  useEffect(() => {
    setBlue(scrollY < 100);

    return () => setBlue(true);
  }, [scrollY]);

  const handleNav = () => {
    const newState = !isMenuOpen;

    setList({
      opacity: newState ? 1 : 0,
      y: newState ? 0 : -100,
      pointerEvents: newState ? 'all' : 'none',
    });

    setMenuOpen(newState);
    isSubMenuOpen && setSubMenuOpen(newState);
  };

  const handleSubMenu = () => {
    const newState = !isSubMenuOpen;

    setSubMenuOpen(newState);
  };

  router.events.on('routeChangeStart', () => {
    isSubMenuOpen && handleSubMenu();
  });

  router.events.on('routeChangeComplete', () => {
    dispatch({ type: 'SET_INVERTED_MENU', payload: false });
  });

  useEffect(() => {
    setList({
      opacity: isMobile ? 0 : 1,
      y: isMobile ? -100 : 0,
      pointerEvents: isMobile ? 'none' : 'all',
    });
  }, [isMobile]);

  const mobileMenuIsOpen = isMobile && isMenuOpen;

  useEffect(() => {
    if (isIE11 && typeof window !== 'undefined' && headerRef.current) {
      const Stickyfill = require('stickyfilljs');
      Stickyfill.add(headerRef.current);
    }
  }, []);

  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    initialLoad && setInitialLoad(false);

    // !isMouseMethod && !isMobile && skipToMain.current && skipToMain.current.focus();

    if (isMenuOpen) {
      handleNav();
    }

    return () => setInitialLoad(true);
  }, [router.pathname]);

  // todo: populate / generate from data from container
  const SubMenuWrap = (
    <SubMenuList ref={menuRef}>
      <SubMenuItem>
        <NextLink href="/grocery">
          <MenuCard tabIndex="0" onClick={() => setSubMenuOpen(null)} onKeyDown={(e) => e.keyCode === 13 && router.push('/grocery') && setSubMenuOpen(null)}>
            <CardImageWrap>
              <CardImage src={'/static/menu/menu-grocery.jpg'} />
            </CardImageWrap>
            <CardTitle>
              <CardTitleWrap>
                <CardHeading>InHome Grocery</CardHeading>
              </CardTitleWrap>
            </CardTitle>
          </MenuCard>
        </NextLink>
      </SubMenuItem>
      <SubMenuItem>
        <NextLink href="/returns">
          <MenuCard tabIndex="0" onClick={() => setSubMenuOpen(null)} onKeyDown={(e) => e.keyCode === 13 && router.push('/returns') && setSubMenuOpen(null)}>
            <CardImageWrap>
              <CardImage src={'/static/menu/menu-returns.jpg'} />
            </CardImageWrap>
            <CardTitle>
              <CardTitleWrap>
                <CardHeading>InHome Returns</CardHeading>
              </CardTitleWrap>
            </CardTitle>
          </MenuCard>
        </NextLink>
      </SubMenuItem>
      <SubMenuItem>
        <MenuCard inactive>
          <CardImageWrap>
            <CardImage src={'/static/menu/menu-pharmacy.jpg'} />
          </CardImageWrap>
          <CardTitle>
            <CardTitleWrap>
              <CardHeading>InHome Pharmacy</CardHeading>
              <Badge primary={false}>Coming soon</Badge>
            </CardTitleWrap>
          </CardTitle>
        </MenuCard>
      </SubMenuItem>
    </SubMenuList>
  );

  return (
    <>
      <Fixed style={style} inverted={inverted} shadow={!isBlue} isOpen={isMenuOpen} topBarHidden={hasInHome || useMinifiedMenu || !infoBar} role="navigation" aria-label="main-navigation" ref={navRef}>
        <LogoBar inverted={inverted} />
        <Container>
          <Row>
            <Column all={{ width: 20 }} custom={[{ bp: 1280, width: 18, offsetLeft: 1 }]}>
              <Wrapper ref={mainRef}>
                <Main isMobile={isMobile}>
                  <HeaderLink inverted={inverted} isBlue={isBlue.toString()} href="/" aria-label="home" aria-hidden={mobileMenuIsOpen ? 'true' : 'false'} disabled={mobileMenuIsOpen}>
                    <PlusUpLogo />
                  </HeaderLink>

                  {!hasInHome && !useMinifiedMenu && infoBar && (
                    <Topbar>
                      <TopbarRow>
                        <TopBarColumn all={{ width: 20 }} custom={[{ bp: 1280, width: 18, offsetLeft: 1 }]}>
                          <TopbarContent>
                            <HeaderCopy sizes="15,19">{infoBar}</HeaderCopy>
                          </TopbarContent>
                        </TopBarColumn>
                      </TopbarRow>
                    </Topbar>
                  )}

                  {isMobile &&
                    (!useMinifiedMenu ? (
                      <Hamburger color={inverted ? colors.white : colors.walmart.blue} isActive={isMenuOpen} onClick={handleNav} aria-label="Open Drawer" />
                    ) : (
                      <>
                        {windowWidth > 720 && (
                          <TabletButtons>
                            {buttons &&
                              buttons.map((b, i) => {
                                const btn = (
                                  <HeaderButton size="medium" title={b.name} href={b.href ? b.href : undefined} onClick={b.onClick} targetBlank={b.targetBlank} outline={b.outline} key={i} aria-label={b.name} track={b.track} trackId={b.trackId}>
                                    {b.name}
                                  </HeaderButton>
                                );

                                if (!b.mobileOnly) {
                                  return btn;
                                }
                              })}
                          </TabletButtons>
                        )}
                      </>
                    ))}
                </Main>

                <ListWrapper
                  style={{
                    ...listProps,
                    transform: listProps.y.interpolate((y) => `translate3d(0, ${y}%, 0)`),
                  }}
                >
                  <SignupWrapper>
                    <List role="menubar" aria-label="main-navigation">
                      {Children.map(rightMenu.props.children, (child) => {
                        if (child === null || (isMobile && child.props.desktopOnly)) {
                          return null;
                        }

                        return (
                          <Item inverted={inverted} isSelected={child && router.pathname === child.props.href} role="none" hasInHome={hasInHome}>
                            {React.cloneElement(child, {
                              tabIndex: '0',
                              role: 'menuitem',
                              foo: 'menuitem',
                            })}
                          </Item>
                        );
                      })}
                    </List>
                    {buttons && (
                      <Buttons>
                        {buttons.map((b, i) => {
                          const btn = (
                            <HeaderButton title={b.name} variant="primary" size="large" href={b.href ? b.href : undefined} onClick={b.onClick} targetBlank={b.targetBlank} outline={b.outline} key={i} aria-label={b.name} track={b.track} trackId={b.trackId}>
                              {b.name}
                            </HeaderButton>
                          );

                          if (isMobile && b.mobileOnly) {
                            return btn;
                          }

                          if (!b.mobileOnly) {
                            return btn;
                          }
                        })}
                      </Buttons>
                    )}
                  </SignupWrapper>

                  {isMobile && (
                    <ChatWrapper>
                      <span style={{ marginRight: '10px' }}>Need help? </span>
                      <ChatLink href="/" showIcon={false}>
                        Live chat with us
                      </ChatLink>
                    </ChatWrapper>
                  )}
                </ListWrapper>
              </Wrapper>
            </Column>
          </Row>
        </Container>
      </Fixed>
      <SubMenu hasInHome={hasInHome} isVisible={isSubMenuOpen} toggle={handleSubMenu}>
        {SubMenuWrap}
      </SubMenu>
    </>
  );
});

export default Header;
